const problemMapping = [
  { "number": 1, "id": "r7s6" },
  { "number": 2, "id": "dqt1" },
  { "number": 3, "id": "8bh9" },
  { "number": 4, "id": "fim1" },
  { "number": 5, "id": "ubx4" },
  { "number": 6, "id": "m4wb" },
];

export default problemMapping;
