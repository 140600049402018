import React,{useState,useEffect} from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";

const QuestionMd = ({problemId}) => {
  const [post, setPost] = useState("");
  const [fileName, setFileName] = useState(`statement_${problemId}.md`)

  useEffect(() => {
      setFileName(`statement_${problemId}.md`);
  }, [problemId])

  useEffect(() => {
    import(`../../problemStatements/${fileName}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [fileName]);

  return(
    <Container>
      <div className="mark-down-file-content">
        <Markdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
          {post}
        </Markdown>
      </div>
    </Container>
  );
};

      //<Markdown
          //options={{
            //overrides: {
              //inlineMath: {
                //component: "span",
                //props: { className: "inline-math" },
              //},
              //math: { component: "span", props: { className: "block-math" } },
            //},
            //extensions: [extendedLatex],
          //}}
        //>
          //{post}
        //</Markdown>

export default QuestionMd;

const Container = styled.div`
  .mark-down-file-content *{
    color:#eee;
  }

  .mark-down-file-content{
    font-weight: 200;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    
    math *{
      font-family:CMU Serif;
      font-weight: 500;
      font-size: 1.2rem;
    }
    
    .katex-html{
      display:none;
    }
    
    a{
      color:lightblue;
    }

    img {
      width: 100%;
      margin: 25px 0;
      display: block;
    }

    .small-img {
      width: 45%;
      min-width: 200px;

      @media only screen and (max-width: 1100px) {
        width: 100%;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div,
    span,
    b {
      color:#eee;
      font-family: "Poppins", sans-serif;
      letter-spacing: 0.03rem;
    }

    code {
      font-family:monospace;
      background-color: #1c151e;
      color: #eee;
      padding: 10px 15px;
      /* border-radius: 5px; */
      font-size: 1rem;
      display: block;
    }

    pre {
      width: 100%;
      background-color: #f6f8fa;
      border-radius: 6px;
      font-weight: 200;
      margin: 20px 0;
      font-size: 0.8rem;
      line-height: 1.75rem;
      letter-spacing: 0.08rem;
      overflow: hidden;
      font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
        Liberation Mono, monospace;

      @media only screen and (max-width: 1100px) {
        overflow-x: scroll;
        border-radius: 5px;
      }

    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
    }

    p,
    li {
      font-weight: 200;
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 10px;
      letter-spacing: 0.05rem;
    }

    ul {
      margin-left: 5%;
      width: 95%;

      li {
        margin: 15px;
      }
    }

    strong {
      color: white;
      font-weight: 500;
    }

    b {
      font-weight: 500;
      margin: 15px 0;
    }
  }
`;
