import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import QuestionMd from '../Components/QuestionMd';
import problemMapping from '../Assets/problemMapping';
import { useHistory } from 'react-router-dom';

const Uncode = () => {
    const [inputValue, setInputValue] = useState('');
    const [outputValue, setOutputValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showProblems, setShowProblems] = useState(false);
    const [selectedProblem, setSelectedProblem] = useState(problemMapping[0]);

    const params = useParams();
    const { problemId } = params;

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://clumsy-eel-headscarf.cyclic.app/problem', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${btoa('uncode:Pkunocde@69')}`,
                },
                body: JSON.stringify({
                    problemId: selectedProblem.id,
                    input: inputValue,
                }),
            });


            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error fetching data:', response.statusText, errorText);
                throw new Error('API request failed');
            }

            const rawData = await response.text();
            // console.log('Raw response:', rawData);
            //   console.log('Raw response:', rawData.result);
            //   const jsonData = JSON.parse(rawData);
            //   console.log('jsonData:', jsonData);
            //   console.log('jsonData.result:', jsonData.result);
            //   setOutputValue(jsonData.result);
            setOutputValue(rawData);


        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleProblemSelect = (problem) => {
        setInputValue("");
        setOutputValue("");
        // window.location.href = `/pRzf3MzF/${problem.id}`;
    };


    useEffect(() => {
        if (problemId) {
            const foundProblem = problemMapping.find(problem => problem.id === problemId);
            if (foundProblem) {
                setSelectedProblem(foundProblem);
            } else {
                console.error('Problem not found for the given problemId:', problemId);
            }
        }
    }, [problemId]);

    useEffect(() => {
        // console.log(selectedProblem);
    }, [selectedProblem])

    return (
        <Container>
            <LocalNav>
                <div className="left">
                    <div className="select" onClick={() => setShowProblems(!showProblems)}>
                        <div className="text">Problem {selectedProblem.number}</div>
                        {
                            showProblems ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                    </div>
                    {/* <p className='problem-id'>{selectedProblem.id}</p> */}
                    {
                        showProblems ? (
                            <div className="select-options">
                                <div className="questions">
                                    {problemMapping.map((problem) => (
                                        <Link to={`/xtlYcP3f/uncode-finals/${problem.id}`}
                                            key={problem.number}
                                            className={`question ${selectedProblem.number === problem.number ? 'selected' : ''}`}
                                            onClick={() => {setShowProblems(!showProblems); handleProblemSelect();}}
                                        >
                                            Problem{" "}{problem.number}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ) : null
                    }
                </div>
                <div className="right">
                    {/* <a href="/">Show problem statement</a>
                    <a href="/">View on HackerRank</a> */}
                </div>
            </LocalNav>
            <MainContent>
                <div className="left">
                    <div className="problem">
                        <QuestionMd problemId={selectedProblem.id} />
                    </div>
                </div>
                <div className="right">
                    <div className="input-box">
                        <h3>Your input : </h3>
                        <textarea
                            cols="30"
                            rows="2"
                            placeholder='Input...'
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        ></textarea>
                    </div>
                    <button className='submit-btn' onClick={handleSubmit}>
                        {loading ? (
                            <>Loading...</>
                        ) : (
                            <>Generate Output</>
                        )}
                    </button>
                    <div className="output-box">
                        <h3>Output : </h3>
                        <div className="result">
                            {outputValue}
                            {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste, laborum. Quibusdam provident et dolore impedit quisquam maiores, molestias veritatis. Minima quod hic ad quisquam consequuntur quasi ducimus maxime excepturi ratione.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste, laborum. Quibusdam provident et dolore impedit quisquam maiores, molestias veritatis. Minima quod hic ad quisquam consequuntur quasi ducimus maxime excepturi ratione.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste, laborum. Quibusdam provident et dolore impedit quisquam maiores, molestias veritatis. Minima quod hic ad quisquam consequuntur quasi ducimus maxime excepturi ratione. */}
                        </div>
                    </div>
                    {/* {loading ? (
                        <div className="output-box">
                            <h3>Loading...</h3> 
                        </div>
                    ) : (
                        outputValue !== null ? (
                            <></>
                        ) : null
                    )} */}
                </div>
            </MainContent>
        </Container>
    );
};

export default Uncode;

const Container = styled.div`
    background-color: #000;
`

const LocalNav = styled.div`
    height: 60px;
    width: 100vw;
    background-color: #0c0813;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #464646;

    padding: 0 20px;

    .left{
        position: relative;
        display: flex;
        align-items: center;

        .select{
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 5px 20px;
            background-color: #1c151e;
            border-radius: 100px;
            border: 1px solid #afabab;

            .text{
                font-size: 0.85rem;
                color: white;
            }

            svg{
                margin-left: 5px;
                fill: white;
                font-size: 1rem;
                margin-right: -5px;
            }
        }

        .problem-id{
            margin-left: 10px;
            font-size: 0.85rem;
            color: white;
            font-weight: 200;
        }

        .select-options{
            position: absolute;
            top: 41.5px;
            left: -7.5px;
            width: 275px;
            background-color: #0c0813;
            border: 1px solid #555555;
            border-radius: 6px;

            .questions{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 7.5px 0px 0px 7.5px;

                .question{
                    cursor: pointer;
                    width: 100%;
                    height: 40px;
                    background-color: #1c151e;
                    border-radius: 6px;
                    margin: 0 7.5px 7.5px 0;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #5a4241;
                    text-decoration: none;
                    border: 1px solid #525253;
                    display: grid;
                    place-items: center;

                    &:hover{
                        color: white;
                    }
                }
                
                .selected{
                    color: #333;
                    background-color: #e5e5e5;

                    &:hover{
                        color: #333;
                    }
                }
            }
        }
    }

    .right{
        .time{
            display: flex;
            align-items: center;
            
            p{
                color: #4ea734;
                font-size: 1.25rem;
                font-weight: 500;
                margin-right: 10px;
                letter-spacing: 0.25rem;
                /* margin-bottom: -3px; */
                padding: 5px 10px;
                background-color: #1c151e;
                border-radius: 100px;
            }
            
            b{
                color: #fff;
                font-size: 0.8rem;
                font-weight: 200;
                margin-right: 10px;
            }
        }
        a{
            font-size: 0.9rem;
            margin-left: 20px;
            font-weight: 300;
            text-decoration: none;
        }   
    }
`

const MainContent = styled.div`
    height: calc(100vh - 60px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;

    .left{
        width: 60%;
        border: 1px solid #555;
        height: calc(100vh - 100px);
        border-radius: 6px;
        background-color: #0c0813;
        overflow-y: scroll;

        .problem{
            padding: 20px;
            
            h2{
                color: #f5f5f5;
                font-weight: 500;
                margin-bottom: 2%;
                font-size: 2rem;
            }

            h3{
                font-size: 1.25rem;
                color: #f5f5f5;
                font-weight: 500;
                margin: 15px 0 5px 0;   
            }


            p{
                color: #c4c4c4;
                font-weight: 300;
                font-size: 0.9rem;
                line-height: 1.5rem;
                margin-bottom: 5px;
                letter-spacing: 0.07rem;
            }
        }

    }

    .right{
        height: calc(100vh - 100px);
        width: calc(40% - 20px);
        border: 1px solid #555;
        padding: 10px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        border-radius: 6px;

        background-color: #0c0813;
       
        h3{
            font-size: 1.25rem;
            color: #f5f5f5;
            font-weight: 500;
            margin: 0 0 15px 0;   
        }

        .input-box{
            width: 100%;
            height: calc(50% - 40px);
            /* background-color: orange; */
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            textarea{
                font-family:monospace;
                width: 100%;
                height: 100%;
                padding: 20px;
                font-size: 1.15rem;
                border-radius: 6px;
                border: 1px solid #555;
                background-color: #1c151e;
                outline: none;
                color: white;
            }
            
        }
        
        .submit-btn{
            cursor: pointer;
            padding: 7.5px 12.5px;
            border-radius: 5px;
            /* border: 1px solid #555; */
            border: none;
            background-color: #5a4241;
            color: white;
            /* margin: 20px 0 40px 0; */
        }
        
        .output-box{
            /* margin-bottom: 20px; */
            /* margin-top: 40px; */
            width: 100%;
            height: calc(50% - 40px);
            /* background-color: orange; */
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            .result{
                font-family:monospace;
                overflow-y: scroll;
                width: 100%;
                height: 100%;
                padding: 20px;
                font-size: 1.15rem;
                border-radius: 6px;
                border: 1px solid #555;
                background-color: #1c151e;
                color: white;
                white-space: pre-wrap;
            }
        }
    }


`
