const members = [
    {'name': ' Ariyan Bhaumik', 'profilePic' : 'mem1', 'linkedin': 'https://www.linkedin.com/in/ariyan-bhaumik-1a7864245?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app', 'github': 'https://github.com/AriyanBhaumik'},
    {'name': ' Arin Ray', 'profilePic' : 'https://media.vanityfair.com/photos/64aecd3d4bf5235b00ae4462/1:1/w_1333,h_1333,c_limit/zayn-malik.jpg', 'linkedin': 'https://www.linkedin.com/in/arin-ray-36bb9923a/', 'github': 'https://github.com/ArinRay207'},
    {'name': 'Aryan Paul', 'profilePic' : './nayak1.png', 'linkedIn': 'https://www.linkedin.com/in/aryan-paul-b67887218/', 'github': 'https://github.com/pauljubcse'},
    {'name': ' Atanu Nayak', 'profilePic' : 'CodeClub-JU-Website/client/src/Assets/Images/nayak2.png', 'linkedin': 'https://www.linkedin.com/in/atanu-nayak-profile/', 'github': 'https://github.com/NayakPenguin/'},
    {'name': ' Farhan Ikbal', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'https://www.linkedin.com/in/farhanikbal0710/', 'github': 'https://github.com/FarhanMig24'},
    {'name': ' Jyotishman Sarkar ', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'https://www.linkedin.com/in/jyotishman-sarkar-423510258/', 'github': 'https://github.com/jyoman19'},
    {'name': ' Om Mittal ', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'www.linkedin.com/in/om-mittal', 'github': 'https://github.com/Om-Mittal'},
    {'name': ' Parthiv Sarkar', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'https://www.linkedin.com/in/parthiv-sarkar-19414b204/', 'github': 'https://github.com/parthivxx'},
    {'name': ' Priyam Saha', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'https://www.linkedin.com/in/priyamsaha2003/', 'github': 'https://github.com/priyam-03'},
    {'name': ' Rajdeep Khaskel ', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'https://www.linkedin.com/in/rajdeepkhaskel/', 'github': ' https://github.com/rajdeepkhaskel'},
    {'name': ' Sounak Ghosh', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'https://www.linkedin.com/in/sounak-ghosh-774932224/', 'github': 'https://github.com/SounakGhosh07/'},
    {'name': ' Sushmit Dasgupta', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'https://www.linkedin.com/in/sushmit14/', 'github': 'https://github.com/sushmit14/'},
    {'name': ' Upayan De', 'profilePic' : '../Assets/Images/nayak1.png', 'linkedin': 'https://www.linkedin.com/in/upayan-de-81545a222/', 'github': 'https://github.com/de-upayan'}
]

export default members;