import React, { useState, useEffect } from "react";
import { Link,Routes, Route,useNavigate } from "react-router-dom";
import styled from "styled-components";
import ScrollToTop from "./Components/ScrollToTop";
import LandingPage from "./Screens/LandingPage";
import Uncode from "./Screens/Uncode";

const App = () => {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/xtlYcP3f/uncode-finals/:problemId" element={<Uncode />} />
        {/* <Route path="*" element={<Uncode />} /> */}
      </Routes>
    </div>
  )
}

export default App