import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallMadeIcon from '@material-ui/icons/CallMade';
import members from '../Assets/members';
import nayak1 from '../Assets/Images/nayak2.png';

import mem1 from '../Assets/Images/mem1.jpeg';
import mem2 from '../Assets/Images/mem2.jpeg';
import mem3 from '../Assets/Images/mem3.jpeg';
import mem4 from '../Assets/Images/mem4.png';
import mem5 from '../Assets/Images/mem5.jpeg';
import mem6 from '../Assets/Images/mem6.jpeg';
import mem7 from '../Assets/Images/mem7.jpeg';
import mem8 from '../Assets/Images/mem8.jpeg';
import mem9 from '../Assets/Images/mem9.png';
import mem10 from '../Assets/Images/mem10.jpeg';
import mem11 from '../Assets/Images/mem11.jpeg';
import mem12 from '../Assets/Images/mem12.jpeg';
import mem13 from '../Assets/Images/mem13.jpeg';

import event1 from '../Assets/Images/event1.jpeg';
import event2 from '../Assets/Images/event2.jpeg';

const LandingPage = () => {
  const memberImages = [mem1,
    mem2,
    mem3,
    mem4,
    mem5,
    mem6,
    mem7,
    mem8,
    mem9,
    mem10,
    mem11,
    mem12,
    mem13]; 

  return (
    <Container>
      <Navbar>
        <div className='left-links'>
          <div className="logo">
            {/* <img src={logo} alt="" /> */}
            <div className="text">
              <div className="up-text">codeclub</div>
              <div className="down-text">Jadavpur University</div>
            </div>
          </div>
          <div className="menu">
            <a href="/" className='link'>About</a>
            <a href="/" className='link'>Upcoming Events</a>
            <a href="/" className='link'>Our Team</a>
            <a href="/" className='link'>Reach Out To Us</a>
          </div>
        </div>
        <div className="right-links">
          <a href="/" className='github-star-link'>
            <a href='https://www.linkedin.com/company/codeclub-ju/' target='_blank' className="text">Follow us on Linkedin</a>
          </a>
          {/* <div className="version">
              <div className="text">12.8 K | Visits</div>
            </div> */}
        </div>
      </Navbar>
      <div className="hero-page">
        <div className="heading">
          <h1>Jadavpur University</h1>
          <h1 className='gradient-text'>CodeClub</h1>
        </div>
        <div className="small-desc">
          CodeClub JU hosts a plethora of events and tries to cultivate and help people who have an interest in the software domain ranging through ML, Data Analytics, Data Science, Web Dev, Cyber Security, DSA as well as Competitive Programming.
        </div>
        <div className="learn">
          <div className="btn">Get Started</div>
        </div>
        <DancerDownIcon>
          <ExpandMoreIcon style={{ fontSize: '2rem', fill: '#fff' }} />
        </DancerDownIcon>
      </div>
      <About>
        <div className="content">
          <div className="heading">
            <h1 className='gradient-text'>About CodeClub JU</h1>
          </div>
          <img className='logo' src="https://media.licdn.com/dms/image/D560BAQGrvSGoa-68qQ/company-logo_200_200/0/1708685606483?e=1718236800&v=beta&t=zUeDRC3x-Aac7XzO-4tSHzvwFCfF6s7gH1Y9y_5OsHc" alt="" />
          <p>CodeClub JU is a bustling community within the university, brimming with events tailored to software enthusiasts. From machine learning to web development, the club's diverse offerings cater to a wide range of interests within the software domain. With a plethora of workshops and seminars, it serves as a nurturing ground for individuals eager to explore various facets of technology.</p>
          <p>Inclusivity lies at the core of CodeClub JU's ethos, fostering an environment where everyone, regardless of background or expertise, feels welcome. Here, seasoned coders and beginners alike come together to share knowledge and collaborate on projects. It's a place where learning is celebrated, and each member is empowered to reach their full potential.</p>
          {/* <p>Driven by a spirit of innovation, CodeClub JU pushes boundaries and encourages its members to think creatively. Through its emphasis on emerging technologies like data analytics and cyber security, the club inspires a culture of curiosity and experimentation. Ultimately, it's a community where passion for technology fuels continuous growth and discovery.</p> */}
        </div>
      </About>
      <Events>
        <div className="content">
          <div className="heading">
            <h1 className='gradient-text'>Upcoming Events</h1>
          </div>
          <div className="holder">
            <div className="event">
              <img src={event1} alt="" />
              <a href='https://srijanju.in/events/uncode' target='_blank' className="register">
                <div className="text">Register</div>
                <CallMadeIcon />
              </a>
            </div>
            <div className="event">
              <img src={event2} alt="" />
              <a href='https://unstop.com/hackathons/openaimer-srijan-2024-jadavpur-university-kolkata-912806' target='_blank' className="register">
                <div className="text">Register</div>
                <CallMadeIcon />
              </a>
            </div>
          </div>
        </div>
      </Events>
      <Members>
        <div className="content">
          <div className="heading">
            <h1 className='gradient-text'>Team Members</h1>
          </div>
          <div className="holder">
            {members.map((member, index) => (
              <div className="event" key={index}>
                <img src={memberImages[index]} alt="" />
                {/* <img src={mem1} alt="" /> */}
                {/* <img src={nayak1} alt="" /> */}
                {/* <img src={member.profilePic} alt="" /> */}
                <div className="register">
                  <div className="text">{member.name}</div>
                  <a href={member.linkedin} target='_blank'>[linkedin]</a>
                  <a href={member.github} target='_blank'>[github]</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Members>
      <ReachOut>
        <div className="content">
          <div className="heading">
            <h1 className='gradient-text'>Reach Out To Us</h1>
          </div>
          <div className="holder">
            <a href='https://www.instagram.com/code_club_ju?igsh=b3BxcTB1MHRuMTZm' target='_blank' className="event">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" alt="" />
            </a>
            <a href='codeclubjaduni@gmail.com' target='_blank' className="event">
              <img src="https://mailmeteor.com/logos/assets/PNG/Gmail_Logo_512px.png" alt="" />
            </a>
            <a href='https://www.linkedin.com/company/codeclub-ju/' target='_blank' className="event">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/2048px-LinkedIn_icon.svg.png" alt="" />
            </a>
            <a href='https://github.com/ju-codeclub' target='_blank' className="event">
              <img src="https://www.freeiconspng.com/thumbs/github-icon/github-icon-9.png" alt="" />
            </a>
          </div>
        </div>
      </ReachOut>
    </Container>
  )
}

export default LandingPage

const Container = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: black;
  
  /* background-color: pink; */
  margin: 0;

  .hero-page{
    height: 100vh;
    min-height: 500px;
    width: calc(100vw - 20px);
    background-color: #1a191d;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 0 70px;

    background: url("https://i.pinimg.com/originals/e5/6c/66/e56c66eba1bfa52934b9a709e7088d73.gif") no-repeat;
    background-size: 30%;
    background-position: center;

    max-width: 1000px;

    margin: auto;
    
    .heading{
      h1{
          text-align: center;
          font-size: 35px;
          font-weight: 400;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #fb0606, #444444);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
      }

      .gradient-text {
          font-size: 85px;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #d59191, #444444);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          margin: 25px 0;
          font-family: "Orbitron", sans-serif;
          /* font-family: "Micro 5", sans-serif; */
          /* font-family: "Silkscreen", sans-serif; */
          font-optical-sizing: auto;
          font-weight: 600;
          font-style: normal;
      }
    }

    .small-desc{
        color: #cbcaca;
        font-style: italic;
        font-size: 0.95rem;
        font-weight: 200;
        line-height: 2.25rem;
        letter-spacing: 0.1rem;
        margin: 0 0 30px 0;
        text-align: center;
    }


    .btn{
      background: linear-gradient(210deg, #c54169, #444, #f22a2a);
      background-size: 400% 400%;

      -webkit-animation: AnimationName 10s ease infinite;
      -moz-animation: AnimationName 10s ease infinite;
      animation: AnimationName 10s ease infinite;
      border-color: transparent;
      color: #fff;  
      cursor: pointer;
      font-size: 0.8rem;
      padding: 10px 25px;
      border-radius: 20px;
      margin: auto;

      /* border: 1px solid #bfa6a6; */
      &:hover{
      }
    }

    @-webkit-keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }

    @-moz-keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }

    @keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }

    @media screen and (max-width: 599px) {
        padding: 100px 30px;
        height: auto;

        .heading{
          h1{
            font-size: 20px;
          }
          
          .gradient-text{
            font-size: 60px;
          }
        }

        .small-desc{
            font-size: 0.75rem;
        }
    }
  }
`

const Navbar = styled.div`
    height: 70px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 35px;
    /* background-color: rgb(255, 255, 255); */
    background-color: transparent;
    box-shadow: 1px 1px 10px 0 rgb(0 0 0 / 5%);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);

    /* display: none; */

    .left-links{
      display: flex;
      align-items: center;

      .logo{
        display: flex;
        align-items: center;
        /* border: 1px solid black; */
        height: 100%;
        padding: 5px 0;
  
        img{
          height: 40px;
          border-radius: 4px;
          margin-right: 10px;
        }
  
        .text{
          .up-text{
            font-size: 1.25rem;
            font-weight: 500;
            color: #ededf0;
          }
          .down-text{
            font-size: 0.7rem;
            font-weight: 200;
            color: #ededf0;
          }
        }
      }
  
      .menu{
        margin-left: 50px;

        .link{
          font-size: 0.85rem;
          font-weight: 100;
          color: #ededf0;
          margin: 0 15px;
          text-decoration: none;
          letter-spacing: 0.05rem;
        }
      }
    }
    
    .right-links{
      display: flex;
      align-items: center;

      .github-star-link{
        display: flex;
        align-items: center;
        text-decoration: none;
        background: linear-gradient(210deg, #c54169, #bc1651, #f22a2a);
        background-size: 200% 200%;
        /* animation: AnimationName 10s linear infinite; */
        padding: 5px 10px;
        border-radius: 5px;
        
        .text{
          text-decoration: none;
          font-size: 0.75rem;
          font-weight: 200;
          color: #ededf0;
        }

        svg{
          fill: #ededf0;
          font-size: 1.05rem;
        }
      }
      
      .version{
        display: flex;
        align-items: center;
        text-decoration: none;
        background: linear-gradient(150deg,#d6cd16,#2ba4b4,#016df8,#016df8,#016df8);
        background-size: 200% 200%;
        /* animation: AnimationName 10s linear infinite; */
        padding: 5px 10px;
        border-radius: 5px;
        margin-left: 15px;

        .text{
          font-size: 0.85rem;
          color: #ededf0;
          /* font-weight: 700; */
        }
      }

      @keyframes AnimationName {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    }

    @media screen and (max-width: 599px) {
        .menu{
          display: none;
        }
    }

`

const DancerDownIcon = styled.div`
    position: absolute;
    bottom: 30px;

    left: calc(50vw - 15px);
    
    animation: animateDown infinite 1.5s;

    @keyframes animateDown{
        0%, 20%, 50%, 80%, 100%{
            transform: translateY(0);
        }
        40%{
            transform: translateY(5px);
        }
        60%{
            transform: translateY(3p);
        }
    }
    
    @media screen and (max-width: 599px){
      display: none;
    }
`

const About = styled.div`
  min-height: 100vh;  
  background-color: #0c0813;

  padding: 100px 20px;

  
  .content{
    width: 100%;
    max-width: 1000px;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    .heading{
      h1{
          text-align: center;
          font-size: 35px;
          font-weight: 400;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #fb0606, #444444);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
      }

      .gradient-text {
          font-size: 55px;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #ff3c3c,  #d59191);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          margin: 30px 0;
      }
    }

    .logo{
      margin: 50px 0;
      height: 180px;
      border-radius: 100%;
      border: 5px solid white;
    }

    p{
      color: #cbcaca;
      font-size: 0.9rem;
      font-weight: 200;
      line-height: 2.25rem;
      letter-spacing: 0.1rem;
      margin: 0 0 30px 0;
      text-align: center;
    }
  }

  @media screen and (max-width: 599px){
    padding: 20px 30px;

    .content{
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      
      .heading{
        .gradient-text {
            font-size: 35px;
        }
      }

      .logo{
        margin: 50px 0;
        height: 180px;
        border-radius: 100%;
        border: 5px solid white;
      }

      p{
        color: #cbcaca;
        font-size: 0.75rem;
        font-weight: 200;
        line-height: 1.5rem;
        letter-spacing: 0.1rem;
        margin: 0 0 30px 0;
        text-align: center;
      }
    }
  }
`

const Events = styled.div`
  min-height: 100vh;  
  background-color: #0c0813;

  padding: 100px 20px;

  
  .content{
    width: 100%;
    max-width: 1000px;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    .heading{
      h1{
          text-align: center;
          font-size: 35px;
          font-weight: 400;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #fb0606, #444444);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
      }

      .gradient-text {
          font-size: 55px;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #ff3c3c,  #d59191);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          margin: 30px 0;
      }
    }

    .holder{
      display: flex; 
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .event{
        width: 380px;
        aspect-ratio: 1/1;
        margin: 25px;
        background-color: #0a0a0b;
        border: 5px solid white;
        position: relative;

        img{
          width: 100%;
        }

        .register{
          position: absolute;
          background-color: #44c85c;
          top: -20px;
          right: -40px;
          border-radius: 100px;
          padding: 7.5px 20px;
          font-size: 0.8rem;
          color: #333;

          display: flex;
          align-items: center;

          svg{
            font-size: 1rem;
            margin-left: 5px;
          }
        }
      }
    }

    p{
      color: #cbcaca;
      font-size: 0.9rem;
      font-weight: 200;
      line-height: 2.25rem;
      letter-spacing: 0.1rem;
      margin: 0 0 30px 0;
      text-align: center;
    }
  }

  @media screen and (max-width: 599px){
    padding: 20px 30px;
    
    .content{
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      
      .heading{
        .gradient-text {
            font-size: 35px;
        }
      }

      .logo{
        margin: 50px 0;
        height: 180px;
        border-radius: 100%;
        border: 5px solid white;
      }

      .holder{
        .event{
          width: 100%;
          aspect-ratio: 1/1;
          margin: 25px;
          background-color: #0a0a0b;
          border: 5px solid white;
          position: relative;

          img{
            width: 100%;
          }

          .register{
            position: absolute;
            background-color: #44c85c;
            top: -20px;
            right: -40px;
            border-radius: 100px;
            padding: 5px 12.5px;
            font-size: 0.65rem;

            text-decoration: none;

            svg{
              font-size: 0.65rem;
              margin-left: 5px;
            }
          }
        }
      }

      p{
        color: #cbcaca;
        font-size: 0.75rem;
        font-weight: 200;
        line-height: 1.5rem;
        letter-spacing: 0.1rem;
        margin: 0 0 30px 0;
        text-align: center;
      }
    }
  }
`

const Members = styled.div`
  min-height: 100vh;  
  background-color: #0c0813;

  padding: 100px 20px;

  
  .content{
    width: 100%;
    max-width: 1000px;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    .heading{
      h1{
          text-align: center;
          font-size: 35px;
          font-weight: 400;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #fb0606, #444444);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
      }

      .gradient-text {
          font-size: 55px;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #ff3c3c,  #d59191);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          margin: 30px 0;
      }
    }

    .holder{
      display: flex; 
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .event{
        width: 200px;
        aspect-ratio: 1/1;
        margin: 25px;
        background-color: #0a0a0b;
        border: 5px solid white;
        position: relative;
        margin-bottom: 80px;

        img{
          height: calc(100% + 10px);
          width: 100%;
          opacity: 0.75;
        }

        &:hover{
          img{
            opacity: 1;
            transition-duration: 250ms;
          }
        }

        .register{
          position: absolute;
          background-color: #fff;
          top: 200px;
          right: -5px;
          padding: 7.5px 10px;
          font-size: 0.8rem;
          color: #333;
          width: 200px;

          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .text{
            font-size: 1rem;
            font-weight: 500;
            width: 100%;
          }

          a{
            text-decoration: none;
            margin-right: 5px;
            font-weight: 500;
          }

        }
      }
    }

    p{
      color: #cbcaca;
      font-size: 0.9rem;
      font-weight: 200;
      line-height: 2.25rem;
      letter-spacing: 0.1rem;
      margin: 0 0 30px 0;
      text-align: center;
    }
  }

  @media screen and (max-width: 599px){
    padding: 20px 30px;
    
    .content{
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      
      .heading{
        .gradient-text {
            font-size: 35px;
        }
      }

      .logo{
        margin: 50px 0;
        height: 180px;
        border-radius: 100%;
        border: 5px solid white;
      }

      .holder{
        .event{
          width: 100%;
          aspect-ratio: 1/1;
          margin: 25px;
          margin-bottom: 100px;
          background-color: #0a0a0b;
          border: 5px solid white;
          position: relative;

          img{
            width: 100%;
          }

          .register{
            padding: 7.5px 10px;
            font-size: 0.8rem;
            color: #333;
            width: calc(100% + 10px);
            /* right: 0; */
            top: auto;
            bottom: -50px;
            height: 60px;


            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .text{
              font-size: 1rem;
              font-weight: 500;
              width: 100%;
            }

            a{
              text-decoration: none;
              margin-right: 5px;
              font-weight: 500;
            }
          }
        }
      }

      p{
        color: #cbcaca;
        font-size: 0.75rem;
        font-weight: 200;
        line-height: 1.5rem;
        letter-spacing: 0.1rem;
        margin: 0 0 30px 0;
        text-align: center;
      }
    }
  }
`

const ReachOut = styled.div`
  background-color: #0c0813;
  padding: 100px 20px;

  
  .content{
    width: 100%;
    max-width: 1000px;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    .heading{
      h1{
          text-align: center;
          font-size: 35px;
          font-weight: 400;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #fb0606, #444444);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
      }

      .gradient-text {
          font-size: 55px;
          background-color: #f3ec78;
          background-image: linear-gradient(92deg, #ff3c3c,  #d59191);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          margin: 30px 0;
      }
    }

    .holder{
      display: flex; 
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .event{
        width: 60px;
        height: 60px;
        margin: 10px;
        background-color: #0a0a0b;
        border: 2px solid white;
        border-radius: 100%;
        position: relative;

        display: grid;
        place-items: center;

        padding: 10px;

        img{
          width: 100%;
          margin: 0;
        }
      }
    }

    p{
      color: #cbcaca;
      font-size: 0.9rem;
      font-weight: 200;
      line-height: 2.25rem;
      letter-spacing: 0.1rem;
      margin: 0 0 30px 0;
      text-align: center;
    }
  }

  @media screen and (max-width: 599px){
    padding: 20px 30px;
    
    .content{
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      
      .heading{
        .gradient-text {
            font-size: 35px;
        }
      }

      .logo{
        margin: 50px 0;
        height: 180px;
        border-radius: 100%;
        border: 5px solid white;
      }

      .holder{
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .event{
          width: 40px;
          height: 40px;
          margin: 10px;
          background-color: #0a0a0b;
          border: 2px solid white;
          border-radius: 100%;
          position: relative;

          display: grid;
          place-items: center;

          padding: 7px;

          img{
            width: 100%;
            margin: 0;
          }
        }
      }

      p{
        color: #cbcaca;
        font-size: 0.75rem;
        font-weight: 200;
        line-height: 1.5rem;
        letter-spacing: 0.1rem;
        margin: 0 0 30px 0;
        text-align: center;
      }
    }
  }
`
